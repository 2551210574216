import React from 'react'
import { SEO, Wrapper, Header } from '../components'
import styled from '@emotion/styled'
import { LocaleContext } from '../components/Layout'
import theme from '../styles/theme'


const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
    text-align: justify;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
    text-align: center;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 4rem 0 1rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;


const DataTerms = ({
  location,
  pageContext: {
    locale
  }
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const context = { slug: 'terms-and-conditions-of-sale', parent: null };
  
  return (
    <React.Fragment>
      <SEO title={`${i18n.defaultTitleAlt} - Politique d'utilisation des données personnelles`} pathname={location.pathname} locale={locale} />
      <Header {...context} />
      <IndexWrapper>
        <Inner>
          <h2>Politique d'utilisation des données personnelles dans le cadre du site dischr.com</h2>
          <h3>1. Objet du site</h3>
          <ul>
            <li>
              <p>
                dischr.com est le portfolio digital du peintre Rémy Disch.
              </p>
            </li>
          </ul>

          <h3>2. Objet de la présente politique</h3>
          <ul>
            <li>
              <p>
                dischr.com collecte des données concernant l'Utilisateur-ice. 
                <br />Ces données sont traitées conformément aux finalités prévues lors de la collecte.
              </p>
            </li>
          </ul>

          <h3>3. Finalité de la collecte de données</h3>
          <ul>
            <li>
              <p>
                L'adresse email, le nom et le prénom de l'Utilisateur-ice sont utilisées dans le cadre de campagnes d'emailing d'information qui lui sont destinées et auxquelles il/elle a consenti.
                <br />Ces campagnes sont envoyées avec le logiciel Mailchimp (https://mailchimp.com/).
              </p>
              <p>
                dischr.com assure à l'Utilisateur-ice une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur-ice dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
              </p>
            </li>
          </ul>
          <h3>4. Cookies tiers</h3>
          <ul>
            <li>
              <p>
                dischr.com n'utilise aucun cookie tiers
              </p>
            </li>
          </ul>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  )
}

export default DataTerms
